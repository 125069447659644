<template>
  <v-dialog
    v-model="isOpen"
    class="TeamDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="480"
    :persistent="actionInProgress"
  >
    <v-sheet
      color="white"
      class="TeamDialog__card"
      :min-height="460"
      :max-height="636"
    >
      <h1
        class="TeamDialog__title display-3 pt-12 px-12"
        :class="{
          'mb-6': !canEditSomeRules,
          'mb-4': canEditSomeRules,
        }"
        v-text="$t('project.ProjectTeam')"
      />

      <v-btn
        icon
        absolute
        top
        right
        aria-description="close dialog"
        @click="isOpen = false"
      >
        <v-icon v-text="'mdi-close'" />
      </v-btn>

      <AppTextField
        v-show="canEditAllRules"
        ref="quickSearch"
        v-model="quickSearch"
        filled
        :label="$t('project.InvitePeople')"
        hide-details
        style="margin: 0 48px 20px"
        @focus="invite = true"
      >
        <template
          v-if="invite"
          #append
        >
          <v-btn
            icon
            @click.stop="closeInvite"
          >
            <v-icon v-text="'mdi-close-circle'" />
          </v-btn>
        </template>
      </AppTextField>

      <div
        v-show="!invite"
        ref="scrollable"
        class="TeamDialog__scrollable"
      >
        <TeamDialogPermissions
          v-if="permissions"
          :permissions="permissions"
          :readonly="!canEditSomeRules"
          :readonly-editors="!canEditEditors"
          :readonly-assignees="!canEditAssignees"
          :readonly-readonly="!canEditReadonly"
          :loading="actionInProgress"
          @set-permission="setPermission"
          @remove-member="removeMember($event.user.id)"
        />
      </div>

      <div
        v-show="invite"
        ref="scrollable"
        class="TeamDialog__scrollable"
      >
        <TeamMember
          v-for="user in filteredUsersToInvite"
          :key="user.id"
          :user="user"
          :disabled="actionInProgress"
          @click="addTeamMember(user.id)"
        >
          <template #append>
            <v-list-item-action class="my-0 pr-4">
              <v-btn
                icon
                :disabled="actionInProgress"
                @mousedown.stop
                @touchstart.stop
                @click.stop="addTeamMember(user.id)"
              >
                <v-icon v-text="'mdi-plus'" />
              </v-btn>
            </v-list-item-action>
          </template>
        </TeamMember>

        <v-list-item v-if="usersToInvite != null && usersToInvite.length === 0">
          <v-list-item-content class="pl-6">
            <v-list-item-title>{{ $t('project.NoUsersToInviteM') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('project.AllInvitedM') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="filteredUsersToInvite != null && filteredUsersToInvite.length === 0">
          <v-list-item-content class="pl-6">
            <v-list-item-title>{{ $t('project.NoUsersMatchM') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('project.NoUsersMatchQueryM', { quickSearch }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import {
  PROJECT_PERMISSION_LEVEL as PERM,
  DEFAULT_PROJECT_PERMISSION,
} from '../constants'

import TeamDialogPermissions from './TeamDialogPermissions'
import TeamMember from './TeamMember'
import Project from '@/store/orm/project'

const MISSING_PERMISSION = Symbol('missing-permission')

export default {
  name: 'TeamDialog',

  components: {
    TeamDialogPermissions,
    TeamMember,
  },

  props: {
    dialogInstance: { type: Object, required: true },
    projectId: { type: String, required: true },
  },

  data() {
    return {
      quickSearch: '',
      actionInProgress: false,
      invite: false,
    }
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    project() { return Project.find(this.projectId) },

    currentUser() {
      const { $store } = this
      return $store.getters['user/current']
    },

    permissions() {
      const { $store, project } = this
      return project && $store.getters['permission/forProject'](project.id)
    },

    allActiveUsers() {
      const { $store } = this
      const users = $store.getters['user/active']
      return users && users.map(user => ({
        ...user,
        _searchBy: [
          user.firstName,
          user.lastName,
          user.userLogin,
          user.userEmail,
        ]
          .filter(Boolean)
          .map(s => s.toLocaleLowerCase())
          .join(' '),
      }))
    },
    usersToInvite() {
      const { allActiveUsers: users, permissions } = this
      if (users == null || permissions == null) return null

      const memberIds = permissions.map(perm => perm.userId)
      return users.filter(user => !memberIds.includes(user.id))
    },

    searchTokens() {
      const { quickSearch } = this
      return quickSearch
        .split(/\s+?/g)
        .map(s => s.toLocaleLowerCase())
        .filter(Boolean)
    },

    filteredUsersToInvite() {
      const { usersToInvite: users, searchTokens } = this
      if (!searchTokens.length) return users
      return users && users.filter(({ _searchBy }) =>
        searchTokens.every(q => _searchBy.includes(q)))
    },

    currentUserPermissionLevel() {
      const { currentUser, permissions } = this
      if (!currentUser || !permissions) return null
      return permissions
        .find(perm => perm.userId === currentUser.id)?.level ||
        MISSING_PERMISSION
    },

    canEditSomeRules() {
      // Admin can edit all rules
      // Owner can edit all rules
      // Editor can edit assignees and can leave the project
      // Assignee can only leave project
      const { currentUser, currentUserPermissionLevel } = this

      if (!currentUser) return null

      const editorPerms = [PERM.EDITOR.value, PERM.OWNER.value]
      return currentUser.isAdmin || editorPerms.includes(currentUserPermissionLevel)
    },

    canEditAllRules() {
      const { currentUser, currentUserPermissionLevel } = this

      if (!currentUser) return null
      return currentUser.isAdmin || currentUserPermissionLevel === PERM.OWNER.value
    },

    canEditEditors() { return this.canEditAllRules },
    canEditReadonly() { return this.canEditAllRules },
    canEditAssignees() {
      const { canEditAllRules, canEditSomeRules, currentUserPermissionLevel } = this
      return canEditAllRules ||
        (canEditSomeRules && currentUserPermissionLevel === PERM.EDITOR.value)
    },
  },

  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.reset()
        this.fetchPermissions()
      },
    },
  },

  created() {
    this.$store.dispatch('user/getList')
  },

  methods: {
    reset() {
      this.quickSearch = ''
      this.$nextTick(() => this.$refs.scrollable?.scroll?.(0, 0))
    },

    fetchPermissions() {
      this.$store.dispatch('permission/getProjectPermissions', {
        projectId: this.projectId,
      })
    },

    action(promise) {
      this.actionInProgress = true
      return promise.finally(() => { this.actionInProgress = false })
    },

    async closeInvite() {
      this.invite = false
      this.quickSearch = ''
      await this.$nextTick()
      this.$refs.quickSearch.$el.querySelector('input').blur()
    },

    async confirmPermissionChange(permissionLevel) {
      const { $store } = this

      if (permissionLevel === PERM.OWNER.value) {
        return await $store.dispatch('confirm/openDialog', {
          title: this.$t('project.ChangeOwnerQ'),
          subtitle: this.$t('project.ChangeOwnerM'),
          consentLabel: this.$t('project.ChangeOwner'),
        })
      }

      if (permissionLevel === PERM.EDITOR.value) {
        return await $store.dispatch('confirm/openDialog', {
          title: this.$t('project.MakeEditorQ'),
          subtitle: this.$t('project.EditorM'),
          consentLabel: this.$t('project.MakeEditor'),
        })
      }

      return true
    },

    async setPermission({ user: { id: userId }, level: permissionLevel }) {
      const { $store, projectId } = this

      const agreed = await this.confirmPermissionChange(permissionLevel)
      if (!agreed) return

      const actionPayload = { projectId, userId, level: permissionLevel }
      const permissionUpdated = $store.dispatch('permission/updateProjectPermission', actionPayload)
      await this.action(permissionUpdated)
    },

    async removeMember(userId) {
      const { $store, projectId } = this
      const deleted = $store.dispatch('permission/deleteProjectPermission', { projectId, userId })
      await this.action(deleted)
    },

    async addTeamMember(userId, permissionLevel = DEFAULT_PROJECT_PERMISSION) {
      const { $store, projectId } = this
      const created = $store.dispatch('permission/createProjectPermission', {
        projectId,
        userId,
        level: permissionLevel,
      })
      await this.action(created)
    },
  },
}
</script>

<style lang="sass" scoped>
.TeamDialog
  &__card
    position: relative
    padding-bottom: 32px

  &__scrollable
    max-height: 416px
    overflow: hidden auto
</style>
